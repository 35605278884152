import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout slugLine="is a designer, among other things.">
    <SEO title="Hello" barType="landing"/>
    <section className="intro">
    <p>
      I’m Coleman, a professional designer, semi-professional writer, intermittent public speaker and artist, and adoptive midwesterner. I currently spend most of my time doing product and design stuff at <a href="https://ionq.co">IonQ</a> and writing for <a href="https://roadwarrior.blog">The Road Warrior</a>.
    </p>
    <p>
      I co-own a small studio/consultancy called <strong>&amp;c.</strong> <a href="http://kennedycollins.com">with my brother,</a> where we solve business' problems with good design and sane strategy, but it's more or less on ice right now as we both have interesting, high-commitment full-time jobs.
    </p>
    <p>
      My interests and skills are <Link to="/now">pretty wide-ranging</Link>, but they all stem from a love of making things with care and good craft and helping others do the same. If you are into turning ideas into reality in a way that is careful, intentional and humane, we’d get along.
    </p>
    <p>
      You can find me on <a href="https://twitter.com/colemanicewater" target="_blank" rel="noreferrer">twitter</a> and <Link to="/elsewhere">a bunch of other places</Link>, but if you want to get in touch, the best way to do that is to <a href="mailto:hello@colemancollins.com" target="_blank" rel="noreferrer">send me an email</a>.
    </p>
  </section>
    <section className="projects">
    <h2>Here's some stuff I've said, made and done</h2>
    <ul>
      <li><a href="https://theroadwarriorbook.com" target="_blank" rel="noreferrer">The Road Warrior</a>, a book about staying healthy and productive while traveling for work. There's also an <a href="https://roadwarrior.blog">associated blog</a> that I update way more often than I do this site.</li>
      <li><a href="https://github.com/ColemanCollins/complimentron" target="_blank" rel="noreferrer">Complimentron</a>, a robot that says nice things. It was in <a href="http://typeforce.com/" target="_blank" rel="noreferrer">Typeforce</a>.</li>
      <li><a href="http://colemancollins.github.io/bestfriends-gh-pages" target="_blank" rel="noreferrer">Best Friends Online</a>, a silly group of digital friends made for Comedy Hack Day</li>
      <li><a href="https://tacocompass.herokuapp.com" target="_blank" rel="noreferrer">Taco Compass</a>, a taco compass (works best on mobile)</li>
      <li><a href="https://www.youtube.com/watch?v=6_b-_9Ti7gk" target="_blank" rel="noreferrer">Designing The Second Interface</a>, a talk about the design of code</li>
      <li><a href="https://www.thoughtworks.com/insights/blog/modern-web-fundamentally-broken-people-disabilities" target="_blank" rel="noreferrer">The Modern Web Is Broken</a>, an (old but surprisingly not very outdated) article about web accessibility</li>
      <li><a href="https://www.thoughtworks.com/insights/blog/you-are-already-visual-designer" target="_blank" rel="noreferrer">You Are Already A Visual Designer</a>, an article about the visual design tools everyone uses</li>
      <li>Looking for a resume? You can <Link to="/cv">read a CV right here</Link>, or just <a href="https://www.linkedin.com/in/colemancollins" target="_blank" rel="noreferrer">look me up on LinkedIn</a> if you prefer.</li>
    </ul>
  </section>
  </Layout>
)

export default IndexPage
